@import './reset.scss';
@import './fonts.scss';
@import './reset.scss';
@import './carousel.scss';
@import './vector';
@import './abstract/mixin';
@import './wave.scss';

/* 2023 정리 */
@import './symbol';
@import './directions';

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  height: -webkit-fill-available;
  font-size: 10px;
  @media screen and (max-width: 1000px) {
    #left {
      display: none;
    }
    #right {
      margin-left: 0;
    }
  }
  @media screen and (max-width: 375px) {
    font-size: 9px;
  }
  @media screen and (max-width: 320px) {
    font-size: 8px;
  }

  &.hide {
    overflow: hidden;
  }
}

mark {
  display: inline-block;
  line-height: 0;
  padding: 0 0.6rem;
  padding-bottom: 1.2rem;
  background-color: #e1ea68;
}

.PickSectionB-viewport,
.PickReviewCard-viewport {
  padding: 0 1.4rem;
}

#main {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
}

#left {
  height: 100vh;
  display: flex;
  align-items: center;
  position: fixed;
}
#right {
  // height: 100vh;
  width: 100%;
  position: relative;
  margin-left: 50%;
}

body {
  margin: 0;
  height: 100%;
  height: -webkit-fill-available;
}
#root {
  height: 100%;
}
.invisible {
  position: absolute;
  left: -10000px;
  overflow: hidden;
}

.svg-gt {
  @include background-svg($svg-cramp);
  background-repeat: no-repeat;
}
.svg-cramp-rotate {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%) rotate(90deg);
    z-index: -1;
    @include background-svg($svg-cramp);
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(0);
    background-size: contain;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  &.rotate-270 {
    &::after {
      transform: translate(-50%, -50%) rotate(270deg);
    }
  }
}
.svg-lt {
  @include background-svg($svg-cramp);
  background-repeat: no-repeat;
  transform: rotate(180deg);
}
.svg-button-back {
  // @include background-svg($svg-button-back);
  background: url('/assets/images/icons/icon_arrow.png') center center;
  background-repeat: no-repeat;
  background-size: 2.5rem;
  transform: rotate(180deg);
}
.svg-button-close-cross {
  // @include background-svg($svg-as-cross);
  background: url('/assets/images/icons/icon_close.png') center center;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
}
.svg-coupon-stamp {
  @include background-svg($svg-coupon-stamp);
  background-repeat: no-repeat;
  background-position: center;
  border: none !important;
}
.svg-card-outline {
  @include background-svg($svg-card-outline);
  background-repeat: no-repeat;
  background-position: center;
}
.svg-stamp-black {
  @include background-svg($svg-stamp-black);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 24px;
  height: 24px;
}
.svg-cross-black {
  @include background-svg($svg-cross-black);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 10px;
  height: 10px;
}
.svg-logo-odoctor {
  @include background-svg($svg-logo-odoctor);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}
.svg-sns-kakao {
  @include background-svg($svg-sns-kakao);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 2.8rem;
  height: 2.8rem;
}
.svg-sns-naver {
  @include background-svg($svg-sns-naver);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 2.8rem;
  height: 2.8rem;
}
.svg-sns-facebook {
  @include background-svg($svg-sns-facebook);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 2.8rem;
  height: 2.8rem;
}
.svg-nav-my-icon-normal {
  @include background-svg($svg-my-icon);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 2.7rem;
  height: 2.7rem;
}
.svg-nav-my-icon-bold {
  @include background-svg($svg-my-icon-bold);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 2.7rem;
  height: 2.7rem;
}
.svg-nav-pick-icon-normal {
  @include background-svg($svg-pick-icon);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 2.7rem;
  height: 2.7rem;
}
.svg-nav-pick-icon-bold {
  @include background-svg($svg-pick-icon-bold);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 2.7rem;
  height: 2.7rem;
}
.svg-nav-quiz-icon-normal {
  @include background-svg($svg-quiz-icon);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 2.7rem;
  height: 2.7rem;
}
.svg-nav-quiz-icon-bold {
  @include background-svg($svg-quiz-icon-bold);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 2.7rem;
  height: 2.7rem;
}
.svg-nav-home-icon-normal {
  @include background-svg($svg-home-icon);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 2.7rem;
  height: 2.7rem;
}
.svg-nav-home-icon-bold {
  @include background-svg($svg-home-icon-bold);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 2.7rem;
  height: 2.7rem;
}
.svg-bucks-logo {
  @include background-svg($svg-bucks-logo);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.svg-coupon-terminated {
  @include background-svg($svg-coupon-terminated);
  background-repeat: no-repeat;
  background-position: calc(100% - 3%) center;
  background-size: 27%;
}
.svg-button-setting {
  @include background-svg($svg-button-setting);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.svg-receipt-shape {
  @media (max-width: 415px) {
    @include background-svg($svg-receipt-shape-410);
  }
  @media (max-width: 375px) {
    @include background-svg($svg-receipt-shape);
  }
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

.svg-home {
  @include background-svg($svg-home);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.svg-home-active {
  width: 100%;
  height: 100%;
  @include background-svg($svg-home-active);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.svg-pick {
  @include background-svg($svg-pick);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.svg-pick-active {
  width: 100%;
  height: 100%;
  @include background-svg($svg-pick-active);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.svg-quiz {
  @include background-svg($svg-quiz);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.svg-quiz-active {
  width: 100%;
  height: 100%;
  @include background-svg($svg-quiz-active);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.svg-my {
  @include background-svg($svg-my);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.svg-my-active {
  width: 100%;
  height: 100%;
  @include background-svg($svg-my-active);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.svg-button-add-photo {
  @include background-svg($svg-button-add-photo);
  background-repeat: no-repeat;
  background-position: center;
  background-size: initial;
}

.svg-button-delete-photo {
  @include background-svg($svg-button-delete-photo);
  background-repeat: no-repeat;
  background-position: calc(100% - 0.8rem) 0.8rem;
  background-size: 1.8rem 1.8rem;
}

.svg-button-like {
  @include background-svg($svg-button-like);
  background-repeat: no-repeat;
  background-position: center;
  background-size: initial;
}
.svg-button-like-select {
  @include background-svg($svg-button-like-select);
  background-repeat: no-repeat;
  background-position: center;
  background-size: initial;
}

.svg-tab-a {
  width: 2rem;
  height: 2rem;
  @include background-svg($svg-tag-a);
  background-repeat: no-repeat;
  background-position: center;
  background-size: initial;
}
.svg-tab-b {
  width: 2rem;
  height: 2rem;
  @include background-svg($svg-tag-b);
  background-repeat: no-repeat;
  background-position: center;
  background-size: initial;
}

.svg-drop-down {
  @include background-svg($svg-drop-dwon);
  background-repeat: no-repeat;
  background-position: center;
  background-size: initial;
}

.svg-reply-crown {
  @include background-svg($svg-reply-crown);
  background-repeat: no-repeat;
  background-position: center;
  background-size: initial;
}

.owl-carousel {
  touch-action: manipulation;
}

.svg-story-cctv {
  @include background-svg($svg-story-cctv);
  background-repeat: no-repeat;
  background-position: center;
  background-size: initial;
}

.svg-story-certi {
  @include background-svg($svg-story-certi);
  background-repeat: no-repeat;
  background-position: center;
  background-size: initial;
}

.svg-story-preview {
  @include background-svg($svg-story-preview);
  background-repeat: no-repeat;
  background-position: center;
  background-size: initial;
}

.svg-community-comment {
  @include background-svg($svg-community-comment);
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: -1px;
  background-size: contain;
}

.svg-community-like {
  @include background-svg($svg-community-like);
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: -1px;
  background-size: contain;
}

.svg-community-like-fill {
  @include background-svg($svg-community-like-fill);
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: -1px;
  background-size: contain;
}

.svg-write-post {
  @include background-svg($svg-write-post);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.svg-bg-round-white-cross {
  @include background-svg($svg-bg-round-white-cross);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.svg-insert-photo {
  @include background-svg($svg-insert-photo);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.svg-other-phone {
  @include background-svg($svg-other-phone);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.svg-other-kakao-outline {
  @include background-svg($svg-other-kakao-outline);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

// subway icon
.subway-line {
  background: rgb(0, 186, 0);
}
.line-1 {
  background-color: rgb(41, 85, 164);
  &:after {
    content: '1';
  }
}
.line-2 {
  background-color: rgb(0, 186, 0);
  &:after {
    content: '2';
  }
}
.line-3 {
  background-color: rgb(210, 104, 61);
  &:after {
    content: '3';
  }
}
.line-4 {
  background-color: rgb(59, 102, 182);
  &:after {
    content: '4';
  }
}
.line-5 {
  background-color: rgb(121, 71, 151);
  &:after {
    content: '5';
  }
}
.line-6 {
  background-color: rgb(150, 87, 42);
  &:after {
    content: '6';
  }
}
.line-7 {
  background-color: rgb(85, 93, 16);
  &:after {
    content: '7';
  }
}
.line-8 {
  background-color: rgb(180, 56, 103);
  &:after {
    content: '8';
  }
}
.line-9 {
  background-color: rgb(198, 175, 91);
  &:after {
    content: '9';
  }
}
.line-10 {
  /* 수인분당선 */
  background-color: #dba829;
  &:after {
    content: '수인분당선';
  }
}
.line-11 {
  /* 신분당선 */
  background-color: #971f2d;
  &:after {
    content: '신분당선';
  }
}
.line-21 {
  /* 대전 1호선 */
  background-color: #3cb44a;
  &:after {
    content: '1';
  }
}

.icon-symbol-xmark {
  @include background-svg($svg-icon-symbol-xmark);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.icon-directions-arrow-left {
  @include background-svg($svg-icon-directions-arrow-left);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.svg-file-download {
  @include background-svg($svg-file-download);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.svg-ampul-icon {
  @include background-svg($svg-ampul-icon);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
